import * as React from 'react';
import axios from 'axios';
import { setAuthorizationToken } from '../../common/utils';
import inMemoryJWT from '../../../inMemoryJwt';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {List, Edit, ShowButton,Labeled, SimpleList, useRecordContext, AutocompleteInput,  Show, SimpleShowLayout, ArrayField, ReferenceInput, SelectInput, Datagrid, TextField, Create, SimpleForm, TextInput, DateInput, required, EditButton } from 'react-admin';

const ParentEditInput = () => {
    const record = useRecordContext();;
    return (
        <ReferenceInput label="Sous-thème" source="parent"  reference="theme"> 
                        <SelectInput source="id" optionText="title"sx={{width:'50%'}}  disabled={(record.children.length > 0)}  />
        </ReferenceInput>
    );
};

const ThemeTitle = () => {
    const record = useRecordContext();
    return <span>Theme / {record ? `${record.title}` : ''}</span>;
};
export const themeCreate = () => (
    <Create redirect="list">
        <SimpleForm >
        <TextInput source="title"  label="Title" sx={{width:'50%'}} validate={[required()]} />
        <ReferenceInput label="Sous-thème" source="parent" reference="theme"> 
                            <AutocompleteInput source="id" optionText="title"  sx={{width:'50%'}}/>
                        </ReferenceInput>

           
        </SimpleForm>
    </Create>
);

export const ThemeEdit = () => {
    return(
        <Edit title={<ThemeTitle />}>
            <SimpleForm redirect="list">
                <TextInput source="title" sx={{width:'50%'}} validate={required()} />
                <ParentEditInput/>
            </SimpleForm>
        </Edit>
    )
};

export const ThemeShow = () => {
    return(
        <Show title={<ThemeTitle />}>
            <SimpleShowLayout>
                    <TextField source="id"  label="ID"/>
                        <TextField source="title" label="Title" />
                        <ArrayField source="children" label="subtheme">
                              <SimpleList primaryText={record => record.title} />
                        </ArrayField>
                </SimpleShowLayout>
        </Show>
    )
};

export const themeList = () => (
    <List>
        <Datagrid>
            <TextField source="id"  label="ID"/>
            <TextField source="title" label="Title" />
            <ArrayField source="children" label="subtheme">
                 <Datagrid  bulkActionButtons={false}>
                    <Grid container spacing={4}>
                            <Grid item xs={6}>
                                    <TextField source="title"/>
                            </Grid>
                            <Grid item xs={6}>
                                    <EditButton />
                            </Grid>
                         </Grid>
                    </Datagrid>
            </ArrayField>
            <EditButton />
        </Datagrid>
    </List>
);