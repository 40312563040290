import { useState, useEffect } from "react";
import  { redirect } from 'react-router-dom'
import axios from "axios";
import Header from "../../header/index";
import * as Yup from "yup";
import { Scrollbars } from 'react-custom-scrollbars-2'
import { FieldArray, Formik, Form, Field, ErrorMessage, getIn } from "formik";
import Footer from "../../footer/index";
import {addBodyClass, setAuthorizationToken} from '../../common/utils';
import {refreshTokenGen} from '../../../inMemoryJwt.js'
import { useAuthenticated, useGetIdentity  } from 'react-admin';
import {useDispatch, useSelector} from "react-redux";

function FarmForumElementsPage() {
  useAuthenticated();
  const userIdentity = useGetIdentity();
  const [transplantFiles, setTransplantFiles] = useState([]);
  const [coverFiles, setCoverFiles] = useState([]);
  const [delarshipFiles, setDelarshipFiles] = useState([]);
  const [excelUploadFiles, setExcelUploadFiles] = useState([]);
  const [pagePost, setPostsData] = useState([]);
  const [fromError, setfromError] = useState('');
  const [eleEdition, setEdition] = useState('');
  const [formLoading, setLoading] = useState(false);
  const userEmail = userIdentity?.data?.email;
  
  const transplantFile = (e, a, files) => {
    
    setTransplantFiles(files);
  };
  const coverPersonalization = (e, a, files) => {
    setCoverFiles(files);
  };
  const delarship = (e, a, files) => {
    setDelarshipFiles(files);
  };
  const excelFileFun = (e, a, file) => {
    setExcelUploadFiles(file);
  };
 
  const removeBtn = (e, currentIndex, currentList) => {
    if (currentList === "transplateFileRemove") {
      let orginalArray = transplantFiles;
     
      const newSliceArray = orginalArray.filter((value, i) => {
        return i !== currentIndex;
      });
      setTransplantFiles(newSliceArray);
    } else if (currentList === "coverFileRemove") {
      let orginalArray = coverFiles;
      const newSliceArray = orginalArray.filter((value, i) => {
        return i !== currentIndex;
      });
      setCoverFiles(newSliceArray);
    } else if (currentList === "delarshipFileRemove") {
      let orginalArray = delarshipFiles;
      const newSliceArray = orginalArray.filter((value, i) => {
        return i !== currentIndex;
      });
      setDelarshipFiles(newSliceArray);
    } else if (currentList === "excelFileRemove") {
      let orginalArray = excelUploadFiles;
      const newSliceArray = orginalArray.filter((value, i) => {
        return i !== currentIndex;
      });
      setExcelUploadFiles(newSliceArray);
    }
  };
  useEffect(() => {
    let urlLocation = window.location.href;
    urlLocation = urlLocation.split('/');
    urlLocation = urlLocation[urlLocation.length - 2];
    const tokenExpires = localStorage.getItem('tokenexpires');
    let refreshTOk = localStorage.getItem('refresh_token');
    if(parseInt(tokenExpires) < new Date().getTime()){
        refreshTokenGen(refreshTOk);
    }
    setEdition(urlLocation);
  }, []);
  const elementsFormEle = (data, pageDetail) => {
    let commandeData = {};
    let commandeFirst = {
          "customerObjective":data.customerObjective,
          "quantityOrdered":data.quantityOrdered
    }
    let commandeSecond = data.commande;
    commandeData.commandeFormOne = commandeFirst;
    commandeData.commandeFormTwo = commandeSecond;
    const elementId = pageDetail.slug;
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (token) {
      const formData = new FormData();
      formData.append('user', JSON.parse(localStorage.getItem('userDetails'))?.id);
      formData.append('edition', parseInt(eleEdition));
      formData.append('el1', data.transplant[0]);
      formData.append('el2', data.cover[0]);
      formData.append('el3', data.delarship[0]);
      formData.append('shipping', data.excelUpload[0]);
      formData.append('state', 'waiting_puck');
      formData.append('command', JSON.stringify(commandeData));
      formData.append('email', data.emailContact);
      axios.post(process.env.REACT_APP_API_URL + '/projects', formData, { headers:{ Authorization: "Bearer " +  token, "Content-Type": "multipart/form-data"} })
        .then(response => {
            if (response.code === 401) {
                refreshTokenGen(refreshToken).then(challenge => {
                    if(challenge){
                        elementsFormEle(data, pageDetail);    
                    }
                })
            } else {
              setTransplantFiles([]);
              setCoverFiles([]);
              setDelarshipFiles([]);
              setExcelUploadFiles([]);
              const timeoutId = setTimeout(() => {
                setfromError('');
                window.location.href = '/case-content'
                clearTimeout(timeoutId);
              }, 1000);
              setLoading(false);
              setfromError(true);
            }
        })
        .catch(error => {
          if(error.response.status === 401){
            refreshTokenGen(refreshToken).then(challenge => {
              if(challenge){
                  elementsFormEle(data, pageDetail);    
              }
          })
         } else {
          setLoading(false);
          setfromError(false);
         }
        });
    }
  }
  return (
    <>
      <Header />
      <div className="breadCrumbSec">
        <div className="container siteContainer">
          <div className="row">
            <div className="col-12">
              <ul className="resetList breadCrumbList">
                <li>
                  <a href="/farm-forum" className="breadCrumbLink">
                    Farm Forum
                  </a>
                </li>
                 <li>
                  <span>Vos éléments</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pageDataSec withoutsubHead">
        <div className="farmForumPage">
          <div className="container siteContainer">
            <div className="forumPageInner">
            <Scrollbars  style={{ height: 'calc(100vh - 358px)', width: '100%'}} 
                renderscrollbarhorizontal={props => <div {...props} className="scrollbar-horizontal" />}
                renderscrollbarvertical={props => <div {...props} className="scrollbar-vertical"/>}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
             >
              <div className="elementsForm">
                <div className="row">
                  <div className="col-12">
                    <div className="row headerrow">
                      <div className="col labelCol">
                        <p>Vos éléments</p>
                      </div>
                      <div className="col importCol">
                        <p>Importer</p>
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        transplant: transplantFiles,
                        cover: coverFiles,
                        delarship: delarshipFiles,
                        excelUpload: excelUploadFiles,
                        customerObjective:JSON.parse(localStorage.getItem('userDetails')).orders,
                        quantityOrdered:null,
                        commande: [
                          {
                            id: Math.random(),
                            basics: "",
                            quantity:null,
                            address:""
                          }
                        ],
                        emailContact:JSON.parse(localStorage.getItem('userDetails')).email,
                        agreeCheckBox:false
                      }}
                      validationSchema={Yup.object().shape({
                        transplant: Yup.array().min(
                          1,
                          "sélectionnez au moins 1 fichier"
                        )
                        .test(
                            "fileFormat",
                            "Seuls les fichiers Pdf, doc, docx, jpeg, jpg, png, mp4, mov, avi, zip sont autorisés",
                            (value) => {
                              if (value) {
                                const supportedFormats = ['pdf', 'doc','docx', 'jpeg', 'jpg', 'png', 'mp4', 'mov', 'avi', 'zip'];
                                    for(let j = 0; j < value.length; j++){
                                      const fileName = value[j]?.name?.split(".").pop().toLowerCase();
                                        if(!supportedFormats.includes(fileName)){
                                            return false;
                                        };
                                    }
                             }
                              return true;
                            }
                          ),
                        cover: Yup.array().min(
                          1,
                          "sélectionnez au moins 1 fichier"
                        )
                        .test(
                            "fileFormat",
                            "Seuls les fichiers Pdf, doc, docx, jpeg, jpg, png, mp4, mov, avi, zip sont autorisés",
                            (value) => {
                              if (value) {
                                const supportedFormats = ['pdf', 'doc','docx', 'jpeg', 'jpg', 'png', 'mp4', 'mov', 'avi', 'zip'];
                                    for(let j = 0; j < value.length; j++){
                                        const fileName = value[j]?.name?.split(".").pop().toLowerCase();
                                        if(!supportedFormats.includes(fileName)){
                                            return false;
                                        };
                                    }
                             }
                              return true;
                            }
                          )
                        ,
                        delarship: Yup.array().min(
                          1,
                          "sélectionnez au moins 1 fichier"
                        )
                        .test(
                            "fileFormat",
                            "Seuls les fichiers Pdf, doc, docx, jpeg, jpg, png, mp4, mov, avi, zip sont autorisés",
                            (value) => {
                              if (value) {
                                const supportedFormats = ['pdf', 'doc','docx', 'jpeg', 'jpg', 'png', 'mp4', 'mov', 'avi', 'zip'];
                                    for(let j = 0; j < value.length; j++){
                                      const fileName = value[j]?.name?.split(".").pop().toLowerCase();
                                        if(!supportedFormats.includes(fileName)){
                                            return false;
                                        };
                                    }
                             }
                              return true;
                            }
                          ),
                        excelUpload: Yup.array()
                          .min(1, "sélectionnez au moins 1 fichier")
                          .test(
                            "fileFormat",
                            "Seuls les fichiers xls sont autorisés",
                            (value) => {
                              if (value) {
                                const supportedFormats = ["xls", "xlsx"];
                                return supportedFormats.includes(
                                  value[0]?.name?.split(".").pop().toLowerCase()
                                );
                              }
                              return true;
                            }
                          ),
                          commande: Yup.array().of(
                            Yup.object().shape({
                              basics: Yup.string().required("Les bases sont requises"),
                              quantity: Yup.string().required("La quantité est requise"),
                              address: Yup.string().required("L'adresse est requise"),
                            })
                          ), 
                          emailContact: Yup.string()
                            .email('Adresse e-mail invalide')
                            .required('L\'e-mail est requis'),
                          agreeCheckBox:Yup.bool() // use bool instead of boolean
                          .oneOf([true], "Vous devez accepter les Termes et Conditions")
                      })}
                      onSubmit={(values, actions) => {
                        elementsFormEle(values, pagePost);
                        setLoading(true);
                        
                        actions.resetForm();
                      }}
                      
                    >
                      {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className={`formLoading ${formLoading ? 'active' : ''}`}>
                          <div className="row eachColCnt uploadFilecol">
                            <div className="col labelCol eachCol">
                              <p className="eachlabelCol">Votre repiquage </p>
                            </div>
                            <div className="col importCol eachCol">
                              <div className="fileUploadCol">
                                <div className={`iconDownloadAdd ${JSON.stringify(values)}`} >
                                  <Field
                                    className="form-control custominputFile"
                                    id="fileOne"
                                    type="file"
                                    name="transplant1"
                                    multiple={false}
                                    onChange={(event) => {
                                      const transplantNewFiles =
                                        event.target.files;
                                      let transplantMyFiles;
                                      if (transplantFiles.length) {
                                         transplantMyFiles = Array.from(transplantNewFiles);
                                      } else {
                                        transplantMyFiles =
                                          Array.from(transplantNewFiles);
                                      }
                                      setFieldValue(
                                        "transplant",
                                        transplantMyFiles
                                      );
                                      transplantFile(
                                        event,
                                        "avatar",
                                        transplantMyFiles
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor="fileOne"
                                    className="upoadloadIcon"
                                  >
                                    <i className="icon-filedownload iconmoon"></i>
                                  </label>
                                </div>
                                <div className="uploadListCol">
                                  <ul className="resetList filesList">
                                    {[...transplantFiles].map((f, i) => (
                                      <li key={i}>
                                        {f.name}{" "}
                                        <button
                                          type="button"
                                          className="removeItem filesRemove"
                                          onClick={(e) => {
                                            removeBtn(
                                              e,
                                              i,
                                              "transplateFileRemove"
                                            );
                                          }}
                                        >
                                          <i className="icon-icon_close iconmoon closeIcon"></i>
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <ErrorMessage
                                    name="transplant"
                                    component="div"
                                    className="errorMsg"
                                  />
                            </div>
                          </div>
                          <div className="row eachColCnt uploadFilecol">
                            <div className="col labelCol eachCol">
                              <p className="eachlabelCol">
                                Votre personnalisation 4e de couv
                              </p>
                            </div>
                            <div className="col importCol eachCol">
                              <div className="fileUploadCol">
                                <div className="iconDownloadAdd">
                                  <Field
                                    className="form-control custominputFile"
                                    id="secondFile"
                                    type="file"
                                    name="cover1"
                                    multiple={false}
                                    onChange={(event) => {
                                      const coverNewFiles = event.target.files;
                                      let coverMyFiles;
                                      if (coverFiles.length) {
                                         coverMyFiles = Array.from(coverNewFiles);
                                      } else {
                                        coverMyFiles =
                                          Array.from(coverNewFiles);
                                      }
                                      setFieldValue(
                                        "cover",
                                        coverMyFiles
                                      );
                                      coverPersonalization(
                                        event,
                                        "avatar",
                                        coverMyFiles
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor="secondFile"
                                    className="upoadloadIcon"
                                  >
                                    <i className="icon-filedownload iconmoon"></i>
                                  </label>
                                
                                </div>
                                <div className="uploadListCol">
                                  <ul className="resetList filesList">
                                    {[...coverFiles].map((f, i) => (
                                      <li key={i}>
                                        {f.name}{" "}
                                        <button
                                          type="button"
                                          className="removeItem filesRemove"
                                          onClick={(e) => {
                                            removeBtn(e, i, "coverFileRemove");
                                          }}
                                        >
                                           <i className="icon-icon_close iconmoon closeIcon"></i>
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <ErrorMessage
                                    name="cover"
                                    component="div"
                                    className="errorMsg"
                                  />
                            </div>
                          </div>
                          <div className="row eachColCnt uploadFilecol">
                            <div className="col labelCol eachCol">
                              <p className="eachlabelCol">
                                Photo et nom du patron de la concession
                              </p>
                            </div>
                            <div className="col importCol eachCol">
                              <div className="fileUploadCol">
                                <div className="iconDownloadAdd">
                                  <Field
                                    className="form-control custominputFile"
                                    id="thirdFile"
                                    type="file"
                                    multiple={false}
                                    name="delarship1"
                                    onChange={(event) => {
                                      const delarShipNewFiles =
                                        event.target.files;
                                      let delarShipMyFiles;
                                      if (delarshipFiles.length) {
                                        delarShipMyFiles = Array.from(delarShipNewFiles);
                                      } else {
                                        delarShipMyFiles =
                                          Array.from(delarShipNewFiles);
                                      }
                                      setFieldValue(
                                        "delarship",
                                        delarShipMyFiles
                                      );
                                      delarship(
                                        event,
                                        "avatar",
                                        delarShipMyFiles
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor="thirdFile"
                                    className="upoadloadIcon"
                                  >
                                    <i className="icon-filedownload iconmoon"></i>
                                  </label>
                                </div>
                                <div className="uploadListCol">
                                  <ul className="resetList filesList">
                                    {[...delarshipFiles].map((f, i) => (
                                      <li key={i}>
                                        {f.name}{" "}
                                        <button
                                          type="button"
                                          className="removeItem filesRemove"
                                          onClick={(e) => {
                                            removeBtn(
                                              e,
                                              i,
                                              "delarshipFileRemove"
                                            );
                                          }}
                                        >
                                          <i className="icon-icon_close iconmoon closeIcon"></i>
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <ErrorMessage
                                    name="delarship"
                                    component="div"
                                    className="errorMsg"
                                  />
                            </div>
                          </div>
                          <div className="row eachColCnt uploadFilecol">
                            <div className="col labelCol eachCol">
                              <p className="eachlabelCol">
                              Base de données clients
                              </p>
                            </div>
                            <div className="col importCol eachCol">
                              <div className="importInnerCol">
                                <a href="/images/Trame-fichier-routage.xls" className="donwloadLink"  download>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 48 48"
                                    width="48px"
                                    height="48px"
                                  >
                                    <path
                                      fill="#4CAF50"
                                      d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                                    />
                                    <path
                                      fill="#FFF"
                                      d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"
                                    />
                                    <path
                                      fill="#2E7D32"
                                      d="M27 42L6 38 6 10 27 6z"
                                    />
                                    <path
                                      fill="#FFF"
                                      d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"
                                    />
                                  </svg>
                                </a>
                                <div className="excelFieldInfo">
                                  <p>
                                    Téléchargez ce fichier Excel pour y renseigner les informations clients
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row eachColCnt uploadFilecol">
                            <div className="col labelCol eachCol">
                              <p className="eachlabelCol">
                              Votre base de données clients
                              </p>
                            </div>
                            <div className="col importCol eachCol">
                              <div className="fileUploadCol">
                                <div className="iconDownloadAdd">
                                  <Field
                                    className="form-control custominputFile"
                                    type="file"
                                    id="excelFile"
                                    multiple={false}
                                    name="excelUploadFile"
                                    onChange={(event) => {
                                      const excelNewFiles = event.target.files;
                                      let excelMyFiles;
                                      if (excelUploadFiles.length) {
                                        excelMyFiles = Array.from(
                                          excelNewFiles
                                        );
                                      } else {
                                        excelMyFiles =
                                          Array.from(excelNewFiles);
                                      }
                                      setFieldValue(
                                        "excelUpload",
                                        excelMyFiles
                                      );
                                      excelFileFun(
                                        event,
                                        "avatar",
                                        excelMyFiles
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor="excelFile"
                                    className="upoadloadIcon"
                                  >
                                    <i className="icon-filedownload  iconmoon"></i>
                                  </label>
                                </div>
                                <div className="uploadListCol">
                                  <ul className="resetList filesList">
                                    {[...excelUploadFiles].map((f, i) => (
                                      <li key={i}>
                                        {f.name}{" "}
                                        <button
                                          type="button"
                                          className="removeItem filesRemove"
                                          onClick={(e) => {
                                            removeBtn(e, i, "excelFileRemove");
                                          }}
                                        >
                                          <i className="icon-icon_close iconmoon closeIcon"></i>
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <ErrorMessage
                                    name="excelUpload"
                                    component="div"
                                    className="errorMsg"
                                  />
                            </div>
                          </div>
                          <div className="row eachColCnt uploadFilecol marginDivider">
                            <div className="col labelCol eachCol commandeLabel">
                              <p className="eachlabelCol">
                                   Votre commande
                              </p>
                            </div>
                            <div className="col labelCol eachCol commandeForm">
                                <div className="row">
                                      <div className="col smalllabelCol ">
                                      <p className="eachlabelCol">Objectif client</p>
                                      </div>
                                      <div className="col smalllabelCol ">
                                      <p className="eachlabelCol">Quantité commandé</p>
                                      </div>
                                </div>
                                <div className="row colPadd reduePadd">
                                      <div className="col">
                                          <Field name="customerObjective" className="form-control elementinput" type="number" disabled/>
                                        </div>
                                      <div className="col">
                                          <Field name="quantityOrdered" className="form-control elementinput" type="number"/>
                                      </div>
                                </div>
                              </div> 
                        </div>
                            <div className="row eachColCnt uploadFilecol">
                              <div className="col labelCol eachCol commandeForm commandeFormBig">
                                <div className="row colPadd reduePadd">
                                      <div className="col col-4 smalllabelCol">
                                        <p className="eachlabelCol">Bases </p>
                                      </div>
                                      <div className="col col-2 smalllabelCol">
                                        <p className="eachlabelCol"> Quantité</p>
                                      </div>
                                      <div className="col col-5 smalllabelCol">
                                      <p className="eachlabelCol">Adresse</p>
                                      </div>
                                      <div className="col-1 smalllabelCol ">

                                      </div>
                                </div>
                                <div className="row">
                                    <FieldArray name="commande">
              {({ push, remove }) => (
                 <div className="col-12">
                  {values.commande.map((p, index) => {
                    const basics = `commande[${index}].basics`;
                    const quantity = `commande[${index}].quantity`;
                    const address = `commande[${index}].address`;
                    return (
                      <div className="row colPadd reduePadd" key={p.id}>
                        <div className="col col-4">
                        <Field
                          margin="normal"
                          variant="outlined"
                          label="Bases"
                          name={basics}
                          className="form-control elementinput"
                          value={p.basics}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
 <ErrorMessage
                                    name={basics}
                                    component="div"
                                    className="errorMsg"
                                  />
                        </div>
                        <div className="col col-2">
                          <Field
                          label="Bases"
                          type="number"
                          name={quantity}
                          className="form-control elementinput"
                          value={p.quantity}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                         <ErrorMessage
                                    name={quantity}
                                    component="div"
                                    className="errorMsg"
                                  />
                        </div>
                        <div className="col col-5">
                         <Field
                          label="Bases"
                          name={address}
                          className="form-control elementinput"
                          value={p.address}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                         <ErrorMessage
                                    name={address}
                                    component="div"
                                    className="errorMsg"
                                  />
                        </div>
                        <div className="col col-1 closeBtn">
                         <button
                          type="button"
                          color="secondary"
                          className="btn btn-red btn-rect btn-padd-reduce"
                          onClick={() => remove(index)}
                        >
                          x
                        </button>
                        </div>
                      </div>
                    );
                  })}
                  <div className="row">
                  <div className="col col-12">
                  <button
                    type="button"
                    variant="outlined"
                    className="btn btn-red btn-rect btn-padd-reduce"
                    onClick={() =>
                      push({ id: Math.random(), basics: "", quantity: null,  address:"" })
                    }
                  >
                    Add
                  </button>
                  </div>
                  </div>
                </div>
              )}
            </FieldArray>
                                  
                                </div>
                            </div>
                          </div>
                          <div className="row eachColCnt emailFileCol marginDivider">
                          <div className="col labelCol emailLabelCol eachCol">
                              <p className="eachlabelCol">
                                  Email de contact
                              </p>
                            </div>
                            <div className="col importCol eachCol">
                            <div className="emailUploadCol">
                                <div className="iconDownloadAdd">
                                  <Field
                                    className="form-control input elementinput EmailField"
                                    id="emailContact1"
                                    type="email"
                                    name="emailContact"
                                    value={values.emailContact}
                                  />
                                 
                                </div>
                              </div>
                              <ErrorMessage
                                    name="emailContact"
                                    component="div"
                                    className="errorMsg"
                                  />  
                              </div>
                          </div>
                          <div className="row eachColCnt uploadFilecol">
                                <div className="col-12">
                                    <Field   type="checkbox" name='agreeCheckBox' id='agreecheck' label='Check the mark' />
                                    <label htmlFor="agreecheck" className="agreeLabel">Cette base de données sera transmise directement au routeur. En cochant cette case, vous assurez de l'exactitude des informations données ainsi que la correspondance des informations avec l'intitulé de chaque colonne</label>
                                    <ErrorMessage name="agreeCheckBox"   component="div" className="errorMsg" />
                                </div>
                          </div>
                          <div className="row">
                            <div className="col-12 form-group noMargin submitBtnCol">
                              <p className="noMargin text-center">
                                <button
                                  type="submit"
                                  className="submitBtn btn btn-red btn-rect"
                                >
                                  submit
                                </button>
                              </p>
                            </div>
                            <div className="progress">
  <div className="color"></div>
</div>

                            </div>
                            <div className="row">
                                    <div className="col-12">
                                    {fromError &&
                                  <p className="successMsg text-center alertTxt">Votre message a été envoyé avec succès</p>
                               }
                               {fromError === false &&
                                  <p className="errorMsg text-center alertTxt">Oups, quelque chose n'allait pas</p>
                               }
                                    </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              </Scrollbars >
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}


export default FarmForumElementsPage;
