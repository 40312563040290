function Footer() {
    return (
        <>
            <footer className="footer_Sec">
                <div className="container siteContainer">
                    <p className="noMargin"> © 2023 CNH Industrial America LLC. Tous droits réservés. Case IH et CNH Industrial Capital sont des marques déposées de CNH Industrial America LLC. <a href="/mentions-legales" className="weblink">Consultez les mentions légales.</a></p>
                </div>
                <div className="downloadSecCnt">

                </div>

            </footer>
        </>
    );
  }
export default Footer;