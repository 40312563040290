import { useState } from 'react';
import { Modal} from 'bootstrap';
function PreviewModal(props) {
    return (
        <>
        <div className="modal fade" id="previewModalCenter" tabIndex="-1" role="dialog" aria-labelledby="previewModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div className="modal-content mx-auto border-0 bg-transparent">
                    <div className="modal-header w-100 p-10 border-0">
                        <h5 className="modal-title previeMoaltitle" id="previeMoaltitle">{props.modalImgUrl.title}</h5>
                        <button type="button" className="close modal-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body w-100 p-0">
                            <div className="modalImageCnt">
                                <img src={props.modalImgUrl.imgUrl} className='img-fluid' />
                            </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
  }
export default PreviewModal;