import * as React from 'react';
import { List, ShowButton, Show,Edit, SimpleShowLayout, ArrayField, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton } from 'react-admin';

export const usersList = () => (
    <List >
    <Datagrid>
        <TextField source="email" />
        <TextField source="username" />
        <TextField source="orders" />
        <EditButton/>
        <ShowButton/>
    </Datagrid>
</List>
)

export const usersShow = () => (
    <Show>
             <SimpleShowLayout>
                    <TextField source="email" label="Email" />
                    <TextField source="username" label="UserName" />
                    <FileField source="orders" title="Orders" />
                </SimpleShowLayout>
        </Show>
)

export const usersEdit = () => (
<Edit>
        <SimpleForm>
        <TextInput source="email"  disabled/>
        <TextInput source="username" disabled/>
        <TextInput source="orders" />
        <ShowButton/>
   </SimpleForm>
   </Edit>
)