import { AuthProvider } from "react-admin";
import inMemoryJWT from './inMemoryJwt.js';
import { redirect } from "react-router-dom";
import axios from 'axios';
import { setAuthorizationToken } from "./components/common/utils/index.js";

export const authProvider = {
    login: ({username, password }) => {
        const request = new Request(`${process.env.REACT_APP_API_URL}/auth/email/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request)
            .then((response) => {
                console.log(request);
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, refreshToken, tokenExpires, user})    => {
                console.log(token);
                console.log(user);
                inMemoryJWT.setToken(token, refreshToken, tokenExpires);
                if(user.role.name === 'Admin'){
                    window.location.href = window.location.origin + '/admin/editions'
                } else {
                    window.location.href = window.location.origin + '/farm-forum'
                }
                localStorage.setItem('userDetails', JSON.stringify(user));
                localStorage.setItem('permissions', user?.role?.name);
                localStorage.setItem('norefresh', true);
            })
    },
    logout: () => {
        console.log('logout');
        inMemoryJWT.ereaseToken();
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },

    checkAuth: () => {
        const tokenExpires = localStorage.getItem('tokenexpires');
        let refreshTOk = localStorage.getItem('refresh_token');
        console.log('check authenticate');
        if(inMemoryJWT.getToken() ){
            return  Promise.resolve()
        } else {
            console.log('checAuth reject');
            return  Promise.reject()
        }
    },
    checkError: (error) => {
        console.log(error);
        const status = error?.status;
        if (status === 401 || status === 403) {
            inMemoryJWT.ereaseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        console.log(role);
        return (inMemoryJWT.getToken() && role) ? Promise.resolve(role) : Promise.reject();
    },
    getIdentity: () =>{
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        return Promise.resolve({ 
            id: userDetails?.id, 
            fullName:userDetails?.username, 
            avatar: '',
            email:userDetails?.email 
        })
    }
};