import React from "react";
import { Admin, CustomRoutes, ListGuesser, Resource, ShowGuesser, Authenticated } from "react-admin";
import { authProvider } from "./authProvider.js";
import { dataProvider } from "./dataProvider.js";
import { UserList } from "./components/pages/users/index";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CaseContentPage from "./components/pages/casecontent";
import CaseContentDetail from "./components/pages/casecontent/caseContentDetail";
import FarmForumPage from "./components/pages/farmforum";
import Login from "./components/auth/index";
import FarmForumDetailPage from "./components/pages/farmforum/farmForumDetail.js";
import FarmForumElementsPage from "./components/pages/farmforum/yourElements.js";
import FarmValidationPage from "./components/pages/farmforum/validation.js";
import MentionLegalesPage from "./components/pages/mentionslegales";
import { i18nProvider } from './i18nProvider';
import MyLoginPage from './login';

export const StoreFront = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider} i18nProvider={i18nProvider}>
       <CustomRoutes noLayout>
          <Route path="/" element={<Authenticated><CaseContentPage /></Authenticated>} />
          <Route path="/case-content" element={<Authenticated><CaseContentPage /></Authenticated>} />
          <Route path="/case-content/:id" element={<Authenticated><CaseContentDetail /></Authenticated>} />
          <Route path="/farm-forum" element={<Authenticated><FarmForumPage /></Authenticated>} />
          <Route path="/farm-forum/:id" element={<FarmForumDetailPage />} />
          <Route path="/farm-forum/:id/vos-elements"element={<FarmForumElementsPage />} />
          <Route path="/farm-forum/:id/validation" element={<FarmValidationPage />} />
          <Route path="/mentions-legales" element={<MentionLegalesPage />} />
     </CustomRoutes>
</Admin>
);

