import * as actionTypes from '../actions/actionTypes.js';

const initialState = {
    successMsg: false,
     errorMsg: false,
}

export default function yourElementsReducer(state = initialState, action) {
    switch(action.type) {
        case actionTypes.ELEMENT_SUCCESS_MESSAGE:
            return {
                successMsg: true
            }
        case actionTypes.ELEMENT_ERROR_MESSAGE:
            return {
                errorMsg:true
            }
        default:
            return state;
    }
}
