import jsonServerProvider from "ra-data-json-server";
import inMemoryJWT from './inMemoryJwt.js';
import {fetchUtils, addRefreshAuthToDataProvider} from 'react-admin';
import { stringify } from "query-string";
import axios from 'axios';
import { refreshAuth } from './refreshAuth';
import {downloadFile,setAuthorizationToken, addBodyClass} from '../src/components/common/utils';

const dataListProvider = (apiUrl) => {
    const httpClient = (url, options) => {
    // options = {
      //  headers: new Headers({ Accept: 'application/json' }),
    //};
    if(!options){
       options = {
         headers: new Headers({ Accept: 'application/json' }),
      };
    }
    if(!options.headers){
        options.headers =  new Headers({ Accept: 'application/json' });
    }
    let token = inMemoryJWT.getToken();
    if(token){
       token = localStorage.getItem('token');
    }
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
        return fetchUtils.fetchJson(url, options);
    } else {
        inMemoryJWT.setRefreshTokenEndpoint(`${process.env.REACT_APP_API_URL}/auth/refresh`);
        return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
            if (gotFreshToken) {
                options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
            };
            return fetchUtils.fetchJson(url, options);
        });
    }
    };

   return {
       getList: (resource, params) => {
           const { page, perPage } = params.pagination;
           const { field, order } = params.sort;
           const query = {
               sort: JSON.stringify([field, order]),
               range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
               filter: JSON.stringify(params.filter),
           };
           let url = '';
           if(resource === 'users'){
            url = `${apiUrl}/${resource}?page=&limit=1000&filter="",sort=""`;
           } else if(resource === 'event') {
            url = `${apiUrl}/${resource}?page=&limit=1000&sort=[{"orderBy":"date", "order":"${order}"}]`;
           } else {
            url = `${apiUrl}/${resource}?${stringify(query)}`;
           }
            let saveData = [];
           if(resource === 'event'){
            saveData =  httpClient(url).then(({ json }) => ({
                data:json.data,
                total: 10,
            }));
           } else {
             saveData =  httpClient(url).then(({ json }) => ({
                data:json,
                total: 10,
            }));
           }
          
          return saveData;
    },
    getOne: (resource, params) =>{
           const saveData = httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
               data: json,
           }))
           return saveData;
       },
    getMany: (resource, params) => {
           const query = {
               filter: JSON.stringify({ id: params.ids }),
           };
           const url = `${apiUrl}/${resource}?${stringify(query)}`;
           if(resource === 'event'){
                 return httpClient(url).then(({ json }) => ({ data: json.data }));
           }
           return httpClient(url).then(({ json }) => ({ data: json }));
       },
    getManyReference: (resource, params) => {
           const { page, perPage } = params.pagination;
           const { field, order } = params.sort;
           const query = {
               sort: JSON.stringify([field, order]),
               range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
               filter: JSON.stringify({
                   ...params.filter,
                   [params.target]: params.id,
               }),
           };
           const url = `${apiUrl}/${resource}?${stringify(query)}`;
   
           return httpClient(url).then(({ headers, json }) => ({
               data: json,
               total: parseInt((headers.get("content-range") || "0").split("/").pop() || "0", 10),
           }));
       },
    update: (resource, params) =>{
        let bodyUpdateData;
         if(resource === 'media'){
            let dataParMeter = params?.data;
            bodyUpdateData  = new FormData();
             for(const name in dataParMeter) {
                 if((name === 'file' && typeof dataParMeter[name] === 'object')){
                    bodyUpdateData.append(name, dataParMeter[name]?.rawFile);
                 } else if((name === 'event' && typeof dataParMeter[name] === 'object')){
                    let eventId = dataParMeter[name].id;
                    bodyUpdateData.append(`event[id]`, eventId);
                 } else {
                    if(name !== 'file'){
                        bodyUpdateData.append(name, dataParMeter[name]);
                    }
                 }
            }
         } else if(resource === 'event'){
                let dataParMeter = params?.data;
                bodyUpdateData  = new FormData();
                 for(const name in dataParMeter) {
                     if((name === 'preview' && typeof dataParMeter[name] === 'object')){
                        bodyUpdateData.append(name, dataParMeter[name]?.rawFile);
                     } else if((name === 'theme' || name === 'subtheme' || name === 'service' || name === 'dealer') && typeof dataParMeter[name] === 'object'){
                        let fieldId = dataParMeter[name].id;
                        bodyUpdateData.append(`${name}[id]`, fieldId);
                     } else {
                        if(typeof dataParMeter[name] === 'object' && name === 'tags'){ 
                            let tagArr = dataParMeter[name];
                            tagArr.forEach((item, index) => {
                                bodyUpdateData.append(`${name}[${index}][id]`, item['id']);
                            })
                            
                        } else {
                            if(name !== 'preview'){
                                bodyUpdateData.append(name, dataParMeter[name]);
                            }
                        }
                     }
               }
         } 
         else{
             bodyUpdateData = JSON.stringify(params.data);
         }
        const updateSave =  httpClient(`${apiUrl}/${resource}/${params.id}`, {
               method: "PATCH",
               body: bodyUpdateData,
           }).then(({ json }) => ({ data: json }))
           return updateSave;
       },
    updateMany: (resource, params) => {
           const query = {
               filter: JSON.stringify({ id: params.ids }),
           };
           return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
               method: "PUT",
               body: JSON.stringify(params.data),
           }).then(({ json }) => ({ data: json }));
       },
    create: async (resource, params) => {
        let bodyData;
        if(resource === 'models'){
            let dataParMeter = params?.data;
            let dateConveter = new Date(dataParMeter?.date).toISOString();
            dataParMeter['date'] = dateConveter;
            bodyData  = new FormData();
             for(const name in dataParMeter) {
                 if(typeof dataParMeter[name] === 'object'){
                    bodyData.append(name, dataParMeter[name]['rawFile']);
                 } else {
                    bodyData.append(name, dataParMeter[name]);
                 }
             }
         } else if(resource === 'design'){
            let dataParMeter = params?.data;
            bodyData  = new FormData();
             for(const name in dataParMeter) {
                 if(typeof dataParMeter[name] === 'object'){
                    bodyData.append(name, dataParMeter[name]['rawFile']);
                 } else {
                    bodyData.append(name, dataParMeter[name]);
                 }
             }
        } else if(resource === 'media'){
            let dataParMeter = params?.data;
            bodyData  = new FormData();
             for(const name in dataParMeter) {
                 if(typeof dataParMeter[name] === 'object'){
                    bodyData.append(name, dataParMeter[name]['rawFile']);
                 } else {
                    bodyData.append(name, dataParMeter[name]);
                 }
            }
         } else if(resource === 'event'){
            let dataParMeter = params?.data;
            bodyData  = new FormData();
            let dateConveter = new Date(dataParMeter?.date).toISOString();
            dataParMeter['date'] = dateConveter;
            for(const name in dataParMeter) {
                 if(name === 'preview' && dataParMeter[name]['rawFile']){
                    bodyData.append(name, dataParMeter[name]['rawFile']);
                 } else {
                   if(typeof dataParMeter[name] === 'object' && name === 'tags' ){ 
                        let tagArr = dataParMeter[name];
                        for (var i = 0; i < tagArr.length; i++) {
                            bodyData.append(name, tagArr[i]);
                        }
                    } else {
                        bodyData.append(name, dataParMeter[name]);
                    }
                 }
            }
         } else{
             bodyData = JSON.stringify(params.data);
         }
           const { json } = await httpClient(`${apiUrl}/${resource}`, {
               method: 'POST',
               body: bodyData,
           })
           return { data: { ...params.data, id: json.id } };
       },
       delete: (resource, params) =>{
          const delData =  httpClient(`${apiUrl}/${resource}/${params.id}`, {
               method: "DELETE",
           }).then(({ json }) => ({ data: json }))
           return delData;
        },
        deleteMany: (resource, params) => {
        //    console.log('deleteMany');
        //    console.log(params);
        //    const query = {
        //        filter: JSON.stringify({ id: params.ids }),
        //    };
        //    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //        method: "DELETE",
        //    }).then(({ json }) => ({ data: json }));
        const delData =  httpClient(`${apiUrl}/${resource}/${params.ids[0]}`, {
            method: "DELETE",
        }).then(({ json }) => ({ data: [{"statusCode":204}] }));
        
        return delData;
       },
   };
};

export const baseProvider =  dataListProvider(process.env.REACT_APP_API_URL);
export const dataProvider = addRefreshAuthToDataProvider(baseProvider, refreshAuth);
