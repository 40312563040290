import * as React from 'react';
import { TabbedForm, List, Edit, ShowButton,ReferenceField, SelectInput, Show, SimpleShowLayout, ReferenceInput, ArrayField, RichTextField,BooleanInput, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton} from 'react-admin';
import { date } from 'yup';
import { RichTextInput } from 'ra-input-rich-text';
const getIdFunction = () => {
        let winLocation = window.location.href;
        let getProjectId = winLocation.split('/');
        return getProjectId[getProjectId.length - 1];
}
export const designCreate = () => (
    <Create>
        <SimpleForm>
                    <TextInput source="title" multiline={true} label="Title"  validate={[required()]} />
                    <BooleanInput label="Validated" source="validated" disabled hidden  defaultValue={false}/>
                    <BooleanInput label="hascorrection" source="hascorrection" disabled   defaultValue={false}/>
                    <TextInput source="project" label="Project"  disabled defaultValue={getIdFunction()} />
                    <TextInput source="correction" disabled hidden multiline={true} label="Correction"  defaultValue=" " />
                    <FileInput source="preview" validate={[required()]}>
                         <FileField source="src" title="title" validate={[required()]} />
                    </FileInput> 
                    <FileInput source="file"  validate={[required()]} >
                        <FileField source="src" title="title" />
                    </FileInput> 
                    <FileInput source="correctionfile" disabled defaultValue={null}>
                         <FileField defaultValue={null} source="src" title="title" />
                     </FileInput>
        </SimpleForm>
    </Create>
);  
export const designEdit = () => (
    <Edit>
        <SimpleForm>
        <TextInput source="title" multiline={true} label="Title"  validate={[required()]}  />
            <BooleanInput label="Validated" source="validated" />
            <BooleanInput label="hascorrection" source="hascorrection" />
            <TextInput source="project.id" label="Project" disabled defaultValue={getIdFunction()}/>
            <TextInput source="correction" disabled  multiline={true} label="Correction"  />
            <FileInput source="preview">
                <FileField source="src" title="title" />
            </FileInput>   
            <FileInput source="file" validate={[required()]} >
                <FileField source="src" title="title" />
            </FileInput> 
            <FileInput source="correctionfile" disabled>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Edit>
);
export const designShow= () => (
    <Show>
             <SimpleShowLayout>
                   <TextField source="title" multiline={true} label="Title" />
                    <BooleanField label="Validated" source="validated" />
                    <TextField source="correction" label="Correction" />
                    <TextField source="project.id" label="Project" />
                    <FileField source="preview" title="title" />
                    <FileField source="file" title="title" />
                    <FileField source="correctionfile" title="title" label="Correction"/>
                </SimpleShowLayout>
        </Show>
);
export const designList = () => (
    <List hasCreate={false}>
    <Datagrid>
        <TextField source="id" />
        <TextField source="title" label="Title" />
        <BooleanField label="Validated" source="validated" />
        <TextField source="projectId" label="Project" />
        <ShowButton />
    </Datagrid>
</List>
)