import * as actionTypes from '../actions/actionTypes.js';

const initialState = {
    searchFilter: false,
    filterData:false,
    caseContent:[],
    ville:[],
    formData:'',
}

export default function caseContentReducer(state = initialState, action) {
    switch(action.type) {
        case actionTypes.CONTENT_SEARCH_FILTER:
            return {
                ...state,
                searchFilter: true
            }
        case actionTypes.CONTENT_DATA:
            return {
                ...state,
                caseContent:action.contentList
            }
        case actionTypes.SEARCH_ENABLED:
            return {
                ...state,
                filterData:true,
                caseContent:[]
            }
        case actionTypes.VILLE_DATA:
            return {
                ...state,
                ville:action.ville
            }
        case actionTypes.SEARCH_FORM_DATA:
            return{
                ...state,
                formData:action.formValues,
                filterData:action.filterData,
            }
        default:
            return state;
    }
}
