import * as React from 'react';
import axios from 'axios';
import { setAuthorizationToken } from '../../common/utils';
import inMemoryJWT from '../../../inMemoryJwt';
import { TabbedForm, List, Edit, ShowButton, Show, SimpleShowLayout, ArrayField, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton } from 'react-admin';


export const PostCreate = () => (
    <Create>
        <SimpleForm > 
            <TextInput source="title" multiline={true} label="Title"  validate={[required()]} />
            <DateInput label="Publication" source="date" defaultValue={new Date()} />
           
        </SimpleForm>
    </Create>
);

export const PostEdit = () => (
    <Edit>
        <SimpleForm redirect="list">
            <TextInput disabled label="Id" source="id" />
            <TextInput source="title" validate={required()} />
            <DateInput label="Publication" source="date" />
           
        </SimpleForm>
    </Edit>
);

export const PostShow = () => (
    <Show>
        <SimpleShowLayout>
                <TextField source="title" />
                <DateField source="date" locales="fr-FR" label="Date" />
            </SimpleShowLayout>
    </Show>
);

export const PostList = () => (
    <List>
        <Datagrid>
            <TextField source="id"  label="ID"/>
            <TextField source="title" label="Title" />
            <DateField source="date" label="Publication" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);