import inMemoryJWT from './inMemoryJwt.js';
import axios from 'axios';
export const refreshAuth = async () => {
    let tokenExpires = localStorage.getItem('tokenexpires');
    let refreshTOk = localStorage.getItem('refresh_token');
    let token = localStorage.getItem('token');
    if (tokenExpires < new Date().getTime() ) {
        console.log('checkFunctinWork');
        // This function will fetch the new tokens from the authentication service and update them in localStorage
     await axios.post(process.env.REACT_APP_API_URL + '/auth/refresh', void 0, {headers: {Authorization : `Bearer  ${refreshTOk}`}})
        .then(response => {
            console.log('successTxt');
            localStorage.setItem('norefresh', true); 
            if (response?.data?.status === 401) {
                window.location.reload('/login');
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('tokenexpires');
            } else {
                const accessToken = response.data.token;
                const refreshToken = response.data.refreshToken;
                const tokenExpires = response.data.tokenExpires
                inMemoryJWT.setToken(accessToken, refreshToken, tokenExpires);
                localStorage.setItem('token', accessToken);
                localStorage.setItem('refresh_token', refreshToken);
                localStorage.setItem('tokenexpires', tokenExpires);
            }
          //  refreshTokenMulti = false;
        })
        .catch(error => {
            console.log('ErrorTxt');
            localStorage.setItem('norefresh', true); 
            console.log('refresTokenEror');
            console.log(error.response);
            if (error?.response?.data === 401) {
                console.log('expired refreshToken');
                 window.location.reload('/login');
                 localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('tokenexpires');
            }
           // refreshTokenMulti = false;
        });
    }
    return Promise.resolve();
}
