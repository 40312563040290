import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Header from '../../header/index';
import Footer from '../../footer/index';
import SearchForm from '../../common/searchForm/index';
import { Modal} from 'bootstrap';
import VideoModal from '../../common/videoModal/index';
import PreviewModal from '../../common/previewModal/index';
import { useAuthenticated  } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';
import {downloadFile, addBodyClass, addHTMLClass,getScrollHeight,getFullMedia,setAuthorizationToken} from '../../common/utils';
import {refreshTokenGen} from '../../../inMemoryJwt.js';
import axios from "axios";

function CaseContentDetailPage() {
    useAuthenticated();
    let navigate = useNavigate();
    const caseList = useSelector((state) => state.casecontent);
    const dispatch = useDispatch()
    const [modalImg, setImgUrl] = useState({title:'', imgUrl:''});
    const [modalVideo, setVideoUrl] = useState({title:'', videoUrl:''});
    const [contentDetail, setContentDetail] = useState([]);
    const [fullMediaDownload, setfullMediaDownload] = useState([]);
    const [winHeight, setWinHeight] = useState();
    const [calcHeight, setCalcHeight] = useState();

    const previewModal = (e, titleTxt, modalUrl) => {
        setImgUrl({title:titleTxt, imgUrl:modalUrl});
        let myModal = new Modal(document.getElementById('previewModalCenter'));
        myModal.show();
    }
    useEffect(() => {
        function handleResize() {
            let calcHeight = getScrollHeight(true);
            setCalcHeight(calcHeight);
            let sideBarHeight = document.querySelector(".searchFormContainer").clientHeight;
            let winHeight = window.innerHeight;
            if((calcHeight - winHeight) < sideBarHeight){
                winHeight = sideBarHeight + (calcHeight - 57)
            }
            setWinHeight(winHeight);
        }
        addBodyClass();
        addHTMLClass();
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const previewVideo = (e, titleTxt, videoUrl) =>{
        setVideoUrl({title:titleTxt, videoUrl:videoUrl});
        let myModal = new Modal(document.getElementById('videoModalCenter'));
        myModal.show();   
    }
    useEffect(() => {
        let paramsVal = window.location.pathname;
        paramsVal = paramsVal.split('/');
        paramsVal = paramsVal[paramsVal.length - 1];
        const tokenExpires = localStorage.getItem('tokenexpires');
        let refreshTOk = localStorage.getItem('refresh_token');
        if(parseInt(tokenExpires) < new Date().getTime()){
            refreshTokenGen(refreshTOk).then(challenge => {
                if(challenge){
                    getPosts(paramsVal);
                    //getFullMedia(paramsVal); 
                }
            })
        } else {
            getPosts(paramsVal);
            //getFullMedia(paramsVal);
        }
      }, []);
      const getPosts = (caseId) => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
            axios.get(`${process.env.REACT_APP_API_URL}/event/${caseId}`, { headers: setAuthorizationToken(token) })
                .then(response => {
                    if (response.status === 401) {
                        refreshTokenGen(refreshToken).then(challenge => {
                            if(challenge){
                                getPosts(caseId);    
                            }
                        })
                    } else if(response.data){
                        console.log(response.data);
                        setContentDetail(response.data);
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        refreshTokenGen(refreshToken)?.then(challenge => {
                            if(challenge){
                                getPosts(caseId);    
                            }
                        })
                    }
            });
    }
    return (
        <>
        <Header/>
        <div className="breadCrumbSec">
            <div className="container siteContainer">
                  <div className='row'>
                    <div className='col-12'>
                        <ul className="resetList breadCrumbList">
                            <li><Link to="/case-content" className="breadCrumbLink">Case content</Link></li>
                            <li><span>{contentDetail?.title}</span></li>
                        </ul>
                    </div>
                  </div>
            </div>
        </div>
        <div className='pageDataSec'>
          <div className='container siteContainer'>
                <div className='row'>
                    <div className='col-4 sideBarCol'>
                        <div className='sideBarInnerCnt'>
                            <SearchForm redirectPage={true} />
                        </div>
                    </div>
                    <div className='col-8 detailCol'>
                        <div className='detailCntInner downloadSec caseDetailPage'>
                            <div className="fileHeaderList">
                                <div className="row">
                                    <div className='col-8'>
                                        <p className="noMargin">{contentDetail?.title}</p>
                                    </div>
                                    <div className='col-4 text-end'>
                                        <p className="downloadFullFileICon"><button type='button' disabled={contentDetail?.locked} onClick={(e) => getFullMedia(e, contentDetail?.id, contentDetail?.title)} className="donwloadLink"><i className="iconmoon icon-filedownload downloadfileIcon"></i></button></p>
                                    </div>
                                </div>
                            </div>
                            <Scrollbar  style={{ height: `calc(${winHeight}px - ${calcHeight}px)`, width: '100%'}}>
                            <div className='row'>
                                <div className='col-12 caseCntListCol'>
                                    <div className='caseCntList'>
                                        <div className='row headerrow'>
                                            <div className='col typeCol'>
                                                <p>Type</p>
                                            </div>
                                            <div className='col descCol'>
                                                <p>Description</p>
                                            </div>
                                            <div className='col downloadCol'>
                                                <p>Télécharger</p>
                                            </div>
                                        </div>
                                        {contentDetail?.media && contentDetail?.media.map((item, index) => (
                                        <div className='row eachColCnt' key={index}>
                                            <div className='col typeCol eachCol'>
                                                <p className='noMargin text-center'>
                                                {item.type === 'video' &&
                                                    <button type='button' className='eachMeidadisplayBtn mediaBtn' onClick={(e) => previewVideo(e, item?.title, item?.file)}>
                                                        <span className='icon-playicon mediaPlayIcon'>
                                                            <span className='path1'></span>
                                                            <span className='path2'></span>
                                                        </span>
                                                    </button>
                                                }
                                                {item.type === 'image' &&
                                                     <button type='button' className='eachMeidadisplayBtn imgBtn' onClick={(e) => previewModal(e, item?.title, item?.file)}>
                                                        <span className='icon-iconImg imgShowIcon'>
                                                            <span className='path1'></span>
                                                            <span className='path2'></span>
                                                            <span className='path3'></span>
                                                            <span className='path4'></span>
                                                        </span>
                                                   </button>
                                                }
                                                 {item.type === 'file' &&
                                                         <>
                                                          {contentDetail?.locked === false && 
                                                                <a href={`${contentDetail?.locked ? '#' : item?.file}`} target="_blank" className='eachMeidadisplayBtn fileUploadBtn' download={`${contentDetail?.locked ? '' : 'download'}`}>
                                                                    <span className='icon-iconfileUpload  fileIcon'>
                                                                        <span className='path1'></span>
                                                                        <span className='path2'></span>
                                                                        <span className='path3'></span>
                                                                        <span className='path4'></span>
                                                                        <span className='path5'></span>
                                                                        <span className='path6'></span>
                                                                    </span>
                                                                </a>
                                                            }
                                                            {contentDetail?.locked === true &&
                                                                <a href="#" className='eachMeidadisplayBtn fileUploadBtn'>
                                                                    <span className='icon-iconfileUpload  fileIcon'>
                                                                        <span className='path1'></span>
                                                                        <span className='path2'></span>
                                                                        <span className='path3'></span>
                                                                        <span className='path4'></span>
                                                                        <span className='path5'></span>
                                                                        <span className='path6'></span>
                                                                    </span>
                                                                </a>
                                                            }
                                                        </>
                                                   }
                                                </p>
                                            </div>
                                            <div className='col descCol eachCol'>
                                                {item.type === 'video' &&
                                                    <p><a href="#" onClick={(e) => previewVideo(e, item?.title, item?.file)} className="pageLink">{item.description} </a></p>
                                                }
                                                {item.type === 'image' &&
                                                   <p><a href="#" onClick={(e) => previewModal(e, item?.title, item?.file)} className="pageLink">{item.description} </a></p>
                                                }
                                                {item.type === 'file' &&
                                                <>
                                                    {contentDetail?.locked === false &&
                                                        <p><a href={item.file} download className="pageLink">{item.description} </a></p>
                                                    }
                                                    {contentDetail?.locked === true &&
                                                        <p><a href="#" className="pageLink">{item.description} </a></p>
                                                    }
                                                 </>
                                                }
                                            </div>
                                            <div className={`col downloadCol eachCol`}>
                                                {contentDetail?.locked === true && 
                                                    <p><a href="#" disabled={contentDetail?.locked} className="donwloadLink"><i className="iconmoon icon-filedownload downloadfileIcon"></i></a></p>
                                                }
                                                 {contentDetail?.locked === false && 
                                                     <p><a href={item.file} target="_blank" download="download" className="donwloadLink"><i className="iconmoon icon-filedownload downloadfileIcon"></i></a></p>
                                                 }
                                            </div>
                                        </div>
                                         ))}
                                         {contentDetail?.length <= 0 &&
                                            <>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <p className='text-center nodataText'>Aucun média disponible</p>
                                                    </div>
                                                </div>
                                            </>
                                          }
                                        </div>
                                    </div>
                                </div>
                                </Scrollbar>
                                <div className="progress">
                                    <div className="color"></div>
                                 </div>
                        </div>
                   
                    </div>
                </div>
              </div>
          </div>
          <PreviewModal modalImgUrl={modalImg}/>
          <VideoModal modalVideoUrl={modalVideo}/>
        <Footer/>
        </>
    );
  }
export default CaseContentDetailPage;