import * as React from 'react';
import { TabbedForm, List, Edit, ShowButton, SelectInput, Show, SimpleShowLayout, ReferenceInput,ImageField, ArrayField, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton, ReferenceField} from 'react-admin';
import { date } from 'yup';

export const modelCreate = () => (
    <Create>
        <SimpleForm>
                    <DateInput label="Publication date" source="date" defaultValue={new Date()} />
                    <TextInput source="title" multiline={true} label="Title"  validate={[required()]}  />
                    <FileInput source="preview"  validate={[required()]} >
                        <FileField source="src" title="title" />
                    </FileInput>
                    <FileInput source="content"  validate={[required()]} >
                        <FileField source="src" title="title" />
                    </FileInput>
                    {/* <TextInput source="preview" multiline={true} label="Preview" />
                    <TextInput source="content" multiline={true} label="Content" /> */}
                    <ReferenceInput label="edition" source="edition" reference="editions"> 
                            <SelectInput source="title" optionText="title" validate={required()} />
                    </ReferenceInput>

        </SimpleForm>
    </Create>
);

export const modelEdit = () => (
    <Edit>
        <SimpleForm>
                    <DateInput label="Publication date" source="date" defaultValue={date} />
                    <TextInput source="title" multiline={true} label="Title" />
                    <FileInput source="preview">
                        <FileField source="src" title="title" />
                    </FileInput>
                    <FileInput source="content">
                        <FileField source="src" title="title" />
                    </FileInput>
                    <ReferenceInput label="edition" source="edition" reference="editions" sort={{ field: 'title', order: 'ASC' }}>      
                        <SelectInput source="edition" optionText="title" validate={required()} />
                    </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const modelShow = () => (
    <Show>
        <SimpleShowLayout>
                <TextField source="id" disabled />
                <TextField source="title" />
                <TextField source="edition.id" label="Edition" />
                <TextField source="preview" multiline={true} label="preview" />
                <TextField source="content" multiline={true} label="Content" />  
                <DateField source="date" locales="fr-FR" label="Publication date" />
            </SimpleShowLayout>
    </Show>
);

export const modelList = () => (
    <List >
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" />
            <ReferenceField source="editionId" reference="editions" label="Edition" />
            <ImageField source="preview"  label="preview" />
            <FileField source="content" title="title"  />
            <DateField source="date" />
            <EditButton/>
            <ShowButton />
        </Datagrid>
    </List>
);