import * as React from 'react';
import { TabbedForm, List, UrlField, useRedirect, ShowButton,  TabbedShowLayout,  Show, SimpleShowLayout, ReferenceInput, ArrayField, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton } from 'react-admin';
import { setAuthorizationToken } from '../../common/utils';
import inMemoryJWT from '../../../inMemoryJwt';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const createDesign = () => {
    let winLocation =  window.location.href;
    let getArray  = winLocation.split('/');
    let currentId = getArray[getArray.length - 2];
    return currentId;
}
const projectNotify = (e) => {
    const currentId = e.target.closest(`tr`).querySelector('.column-id').innerText;
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    axios.post(`${process.env.REACT_APP_API_URL}/projects/notify/${currentId}`, void 0, { headers: setAuthorizationToken(token) })
    .then(response => { 
        if (response.statusCode === 401) {
            inMemoryJWT.refreshTokenGen(refreshToken).then(challenge => {
                if(challenge){
                    projectNotify(e);    
                }
            })
        } else if(response.data){
             
        }
    })
    .catch(error => {
        if (error.response.data) {
            console.log('failure');
        }
});
}

export const projectList = () => (
    <List>
        <Datagrid>
            <TextField source="id" label="Id" />
            <TextField source="edition.title" label="Edition" />
            <TextField source="user.firstName" label="User"/>
            <ShowButton />
            <Button label="Notify" onClick={projectNotify} value={record => record.id} />
           
        </Datagrid>
    </List>
);

export const projectShow = (props) => {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField label="Id" source="id" />
                <TextField source="edition.title" label="Edition" />
                <TextField source="user.firstName" label="User" />
                <UrlField  source="el1"  label="Votre repiquage"/>
                <UrlField  source="el2"  label="Votre personnalisation 4e de couv"/>
                <UrlField source="el3"  label="Photo et nom du patron de la concession"/>
                <UrlField source="shipping" label="Déposer vos informations clients"/>
                <TextField source="command.commandeFormOne.customerObjective" label="Objectif client" />
                <TextField source="command.commandeFormOne.quantityOrdered" label="Quantité commandé" />
                <ArrayField source="command.commandeFormTwo" label="Votre commande">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="basics" label="Bases" />
                    <TextField source="quantity" label="Quantité" />
                    <TextField source="address" label="Adresse" />
                </Datagrid>
            </ArrayField>
                <a href={`/admin/design/create/${createDesign()}`}
                class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary css-27k8g8-MuiButtonBase-root-MuiButton-root-RaButton-root" tabindex="0" aria-label="Show Design" onClick={()=> createDesign() }><span class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeSmall css-cstir9-MuiButton-startIcon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="RemoveRedEyeIcon"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3"></path></svg></span>Create Design<span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></a>
                </SimpleShowLayout>
        </Show>
   );
};