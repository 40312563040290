import { useState } from 'react';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {setAuthorizationToken} from '../../common/utils/index';
import {refreshTokenGen} from '../../../inMemoryJwt.js'
import axios from 'axios';
import { Modal} from 'bootstrap';

function SuggestedModal(props) {
    let {suggestedDetail} = props;
    const [fromError, setfromError] = useState('');
    const suggestedFormEle = (data, id, userEmail) =>{
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
        if (token) {
            axios.post(process.env.REACT_APP_API_URL + '/editions/advice', {
                "user": userEmail,
                "message": data.message
            }, { headers: setAuthorizationToken(token) })
            .then(response => {
                console.log(response);
                if (response.code === 401) {
                    refreshTokenGen(refreshToken).then(challenge => {
                        if(challenge){
                            suggestedFormEle(data, id);    
                        }
                    })
                } else {
                    setfromError(true);
                    const timeoutId = setTimeout(() => {
                        document.querySelectorAll('#suggestedModalCenter .modal-close')[0]?.click();
                        setfromError('');
                        clearTimeout(timeoutId);
                    }, 1000);
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response.status === 401){
                    refreshTokenGen(refreshToken).then(challenge => {
                        console.log(challenge);
                        if(challenge){
                            suggestedFormEle(data, id);    
                        }
                    })
                }
                setfromError(false);
            });
        }
    }
   return (
        <div className="modal fade" id="suggestedModalCenter" tabIndex="-1" role="dialog" aria-labelledby="suggestedModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title suggestModaltitle" id="suggestModaltitle">Proposition d’articles pour Farm Forum ou Farm Forum Digital</h5>
                    <button type="button" className="close modal-close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                    </button>
                </div>
      <Formik 
       initialValues={{
        title: '',
        message: '' 
    }}
    validationSchema={Yup.object().shape({
        message: Yup.string()
        .required(`ce dépôt est obligatoire`),
    })}
    onSubmit={(values, actions) => {
            suggestedFormEle(values, suggestedDetail.id, suggestedDetail.userEmail);
            actions.resetForm();
        }}
    >
      {props => (
        <div className="modal-body">
            <div className='suggestFormContainer'>
            <Form>
            <div className='row'>
                <div className='col-12 form-group'>
                    <Field as="textarea" className="form-control message" name="message"  placeholder="Description" />
                    <ErrorMessage name="message" component="div" className="errorMsg"/>
                </div>
            </div>
                <div className="row">
                        <div className='col-12 form-group noMargin submitBtnCol'>
                        <p className='noMargin text-center'>
                            <button type="submit" className="submitBtn">
                                Envoyer
                            </button>
                        </p>
                        {fromError &&
                            <p className="successMsg  text-center alertTxt">Votre message a été envoyé avec succès</p>
                        }
                        {fromError === false &&
                            <p className="errorMsg text-center alertTxt">Oups, quelque chose n'allait pas</p>
                        }
                    </div>
                </div>
            </Form>
            </div>
        </div>
        )}
     </Formik>
     </div>
     </div>
     </div>
    )
}
export default SuggestedModal;