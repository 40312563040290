import * as React from 'react';
import axios from 'axios';
import { setAuthorizationToken } from '../../common/utils';
import inMemoryJWT from '../../../inMemoryJwt';
import Grid from '@mui/material/Grid';
import { TabbedForm, List, Edit, ShowButton, Show, Labeled, BooleanInput,BooleanField, SimpleShowLayout, ImageField, ArrayField, ReferenceInput, SelectInput, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, Create, SimpleForm, TextInput, DateInput, required, EditButton, useRecordContext } from 'react-admin';

export const tagsCreate = () => (
    <Create redirect="list">
        <SimpleForm > 
            <TextInput source="title" multiline={true} label="Title" sx={{width:'50%'}}  validate={[required()]} />
        </SimpleForm>
    </Create>
);
const TagTitle = () => {
    const record = useRecordContext();
    return <span>Tags / {record ? `${record.title}` : ''}</span>;
};

export const tagsEdit = () => (
    <Edit title={<TagTitle />}>
       <SimpleForm redirect="list">
            <TextInput source="title" sx={{width:'50%'}} validate={required()} />
        </SimpleForm>
    </Edit>
);
export const tagsShow = () => (
    <Show title={<TagTitle />}>
       <SimpleShowLayout direction="row">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Labeled label="Tag ID">
                        <TextField source="id"  label="ID"/>
                    </Labeled>
                </Grid>
                <Grid item xs={6}>
                     <Labeled label="Title">
                            <TextField source="title" label="Title" />
                    </Labeled>
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
);
export const tagsList = () => (
    <List>
        <Datagrid>
            <TextField source="id"  label="ID"/>
            <TextField source="title" label="Title" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);